// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-post-js": () => import("/mnt/d/git/home/src/templates/product-post.js" /* webpackChunkName: "component---src-templates-product-post-js" */),
  "component---src-templates-inner-page-js": () => import("/mnt/d/git/home/src/templates/inner-page.js" /* webpackChunkName: "component---src-templates-inner-page-js" */),
  "component---src-pages-404-js": () => import("/mnt/d/git/home/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-account-js": () => import("/mnt/d/git/home/src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-contact-js": () => import("/mnt/d/git/home/src/pages/Contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-home-js": () => import("/mnt/d/git/home/src/pages/home.js" /* webpackChunkName: "component---src-pages-home-js" */),
  "component---src-pages-index-js": () => import("/mnt/d/git/home/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pw-forget-js": () => import("/mnt/d/git/home/src/pages/pw-forget.js" /* webpackChunkName: "component---src-pages-pw-forget-js" */),
  "component---src-pages-signin-js": () => import("/mnt/d/git/home/src/pages/signin.js" /* webpackChunkName: "component---src-pages-signin-js" */),
  "component---src-pages-signup-js": () => import("/mnt/d/git/home/src/pages/signup.js" /* webpackChunkName: "component---src-pages-signup-js" */),
  "component---src-pages-thanks-js": () => import("/mnt/d/git/home/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

exports.data = () => import("/mnt/d/git/home/.cache/data.json")

