import { combineReducers } from 'redux'
import sessionReducer from './sessionReducer'
import userReducer from './userReducer'
// import productReducer from './productReducer'

const rootReducer = combineReducers({
  userReducer,
  sessionReducer,
  // productReducer
})

export default rootReducer
